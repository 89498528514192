/* src/pages/ConsentForm.css */
.consent-form-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .consent-form-container h2 {
    margin-bottom: 20px;
  }

  .checkbox-container {
    margin-left: 15px;
    margin-bottom: 20px;
    text-align: left;
  }

  .button-container button i.fa-spin {
    margin-right: 8px;
  }

  .sigCanvas {
    border: 2px solid #000;
    width: 100%;
    height: 150px;
  }

  .signature-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-left: 20px;
  }
  
  .signature-container button {
    margin-top: 10px;
  }
  
  .error {
    margin: 10px;
    color: red;
  }

