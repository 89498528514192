/* src/components/Header.css */
.header {
   background-image: url('../assets/header-bg.png');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    align-items: center;
    color: white;
    padding: 20px 20px;
    height: 150px;
  }
  
  .header-container {
    display: flex;
    align-items: center;
    margin-left: 100px;
    margin-right: 100px; 
    height: 75%;
  }
  
  .logo {
    border-radius: 5%;
    height: 100px;
    content:url('../assets/logo.png');
    font-size: 1.5em;
    margin-left: 50px;
    margin-right: 50px;
  }
  
  .nav {
    display: flex;
    margin-left: 100px;
    margin-right: 100px;
  }
  
  .nav-menu {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    /* background-color: #08499833; */
    font-size: 1em;
  }
  
  .nav-menu li {
    margin-left: 20px;
    border-style: solid;
    border-color: #08499870;
    border-radius: 17px;
    background-color: #ffffff;
    padding: 4px;
  }
  
  .nav-menu li a {
    color: #000000;
    text-decoration: none;
    margin: 5px;
  }
  
  .nav-menu li a:hover {
    font-weight:bold;
  }
  
  @media (max-width: 768px) {
    .header {
      height:130px
    }
  }