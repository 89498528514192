.reset-password-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: url('../assets/reset-password.png') no-repeat center center/cover;
  }
  
  .reset-password-box {
    background-color: rgba(255, 255, 255, 0.9);
    padding: 40px;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 400px;
  }
  
  .reset-password-box h2 {
    margin-bottom: 20px;
    font-family: 'Futura', sans-serif;
    font-size: 24px;
  }
  