body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* All the common css follows */
button {
  background-color: #084898;
  color: #fff;
  padding: 10px;
  cursor: pointer;
  border: none;
  width: max-content;
  min-width: 100px;
  padding: 15px;
  border-radius: 21px;
  font-size: 13px;
  margin-left: 20px;
}

button:hover {
  background-color: #082868;
}

button:disabled {
  background-color: #ccc;
  color: #666;
  cursor: not-allowed;
}

.cancel-button {
  background-color: #d32f2f; /* red color */
  color: #fff;
}

.cancel-button:hover {
  background-color: #B83030;
}

.button-container {
  margin-top: 20px;
  text-align: right
}

.input-container {
  margin-bottom: 15px;
  width: 100%;
  /* display: inline-block; */
  display: flex;
  align-items: center;
}

.input-container label {
  margin-bottom: 5px;
  font-size: 16px;
  color: #101E26;
  /* width: 20%; */
  flex: 1;
}

.input-container input {
  padding: 15px;
  border: 2px solid #101E26;
  border-radius: 17px;
  font-size: 12px;
  margin-left: 20px;
  flex: 2;
}

.form-group {
  margin-bottom: 5px;
  width: 100%;
  /* display: inline-block; */
  display: flex;
  align-items: center;
}

.form-group label {
  font-size: 16px;
  color: #101E26;
  flex: 1;
  margin-left: 20px
}

.form-group input,
.form-group select,
.form-group textarea {
  padding: 15px;
  border: 2px solid #101E26;
  border-radius: 17px;
  font-size: 12px;
  margin-left: 20px;
  margin-bottom: 15px;
  flex: 1;
}
.form-group textarea {
  height: 150px;
}

form .button-container{
  margin-top: 0px;
}

.custom-table {
  width: 100%;
  border-collapse: collapse;
  border: 2px solid #084898;
  margin-left: 20px;
}

.custom-table th,
.custom-table td {
  border: 2px solid #084898;
  padding: 8px;
}

.custom-table th {
  background-color: #084898;
  text-align: left;
  color: #fff;
  font-weight: normal;
}

.success-message {
  color: green;
  margin-bottom: 15px;
}

.error-message {
  color: red;
  margin-bottom: 15px;
}

h1 {
  font-family: 'Futura', sans-serif;
  color: #084898;
}

h2 {
  margin-bottom: 20px;
  font-family: 'Futura', sans-serif;
  font-size: 24px;
  color: #084898;
}

::placeholder {
  color: #8A8D8E;
  opacity: 1; /* Firefox */
}

::-ms-input-placeholder { /* Edge 12 -18 */
  color: #8A8D8E;
  ;
}

@media (max-width: 768px) {
  .form-group {
    display: grid;
  }
}



.navbar {
  background-color: #b3cbec;
  padding: 15px 20px;
  display: flex;
  align-items: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  height: 15px;
}

.nav-links {
  list-style: none;
  display: inline-flex;
  gap: 20px;
}

.nav-links li {
  position: relative;
}

.nav-links li a {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.758);
  font-size: 16px;
  font-weight: bold;
  padding: 10px 15px;
  transition: all 0.3s ease-in-out;
  border-radius: 5px;
}

/* Hover effect */
.nav-links li a:hover {
  background: rgba(255, 255, 255, 0.2);
  transform: scale(1.1);
}

/* Active link styling */
.nav-links li a.active,
.nav-links li a:focus {
  background: white;
  color: #084898;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
}

@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
