/* src/components/Footer.css */
.footer {
    background-color: #084898;
    color: white;
    text-align: center;
    bottom: 0;
    width: 100%;
    opacity: 21%;
    height: 70px;
    align-content: center
  }
