/* src/pages/Login.css */
.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: url('../assets/login.png') no-repeat center center/cover;
  }
  
  .login-box {
    background-color: rgba(255, 255, 255, 0.9);
    padding: 40px;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 400px;
  }
   
  .forgot-password {
    text-align: right;
    margin-bottom: 15px;
  }
  
  .forgot-password a {
    color: #003366;
    text-decoration: none;
    font-size: 14px;
  }
  
  .forgot-password a:hover {
    text-decoration: underline;
  }
  
  .error-message {
    color: red;
    margin-bottom: 15px;
  }