/* src/components/Layout.css */
.layout {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  .content-wrapper {
    display: flex;
    flex: 1;
  }
  
  .side-padding-left, .side-padding-right {
    width: 100px;
    background-color: #084898; /* Dark blue color, 21% opacity */
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    opacity: 21%;
}

  .side-padding-left {
    left: 0;
  }

  .side-padding-right {
    right: 0;
  }
  
  .main-content {
    flex: 1;
    padding: 20px;
    background-color: white; /* Adjust if needed */
  }
  
  .main-wrapper {
    position: relative;
  }
  .outlet-wrapper {
    margin-left: 150px;
    margin-right: 150px;
  }

  @media (max-width: 768px) {
    .side-padding-left, .side-padding-right {
        width: 10px;
    }

    .outlet-wrapper {
        margin-left: 10px;
        margin-right: 10px;
    }
}