/* Styling for the SurgeryConsent component */
.consent-container {
  font-family: Arial, sans-serif;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #f9f9f9;
  max-width: 600px;
  margin: auto;
}

.consent-container h1 {
  color: #333;
  text-align: center;
}

.consent-details {
  margin-top: 20px;
  padding: 10px;
  border-radius: 5px;
  background-color: #fff;
  border: 1px solid #ccc;
}

.consent-details p {
  color: #555;
  margin: 5px 0;
}

.consent-actions {
  margin-top: 20px;
  text-align: center;
}

.consent-actions button {
  margin: 5px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.consent-actions button:hover {
  background-color: #ddd;
}

.sigCanvas {
  border: 2px solid #000;
  width: 100%;
  height: 150px;
}

.consent-form .form-group {
  margin-bottom: 20px;
  margin-top: 20px;
}

.signature-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.signature-container button {
  margin-top: 10px;
}

.error {
  margin: 10px;
  color: red;
}

.otp-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.otp-popup-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.otp-popup-content {
  position: relative;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  z-index: 1001;
  width: 300px;
  text-align: center;
}

.otp-popup-content h2 {
  margin-bottom: 15px;
}

.otp-popup-content input {
  width: 80%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.otp-popup-buttons {
  display: flex;
  justify-content: space-between;
}

.otp-popup-buttons button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
}

.otp-popup-buttons button:hover {
  background-color: #0056b3;
}

.otp-popup-buttons button:last-child {
  background-color: #6c757d;
}

.otp-popup-buttons button:last-child:hover {
  background-color: #5a6268;
}

/* Responsive styles for mobile screens */
@media (max-width: 768px) {
  .consent-container {
      padding: 10px;
      max-width: 100%;
  }

  .consent-actions button {
      padding: 8px 12px;
      font-size: 14px;
  }

  .form-group input {
      padding: 8px;
      font-size: 14px;
      margin: 20px;
  }

  .sigCanvas {
      width: 100%;
      height: 120px;
  }

  .button-container button {
      padding: 8px 16px;
      font-size: 14px;
  }

  .otp-popup-content {
      max-width: 90%;
  }

  .otp-popup-content input {
      width: 100%;
  }

  .otp-popup-buttons button {
      padding: 8px 12px;
  }
  
  .signature-container {
    margin: 20px;
  }
}
