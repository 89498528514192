.manage-users {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto; /* Center the form */
  }
  
  .manage-users-form {
    margin-bottom: 20px;
  }
   
  .edit-button,
  .delete-button {
    background: none;
    border: none;
    cursor: pointer;
    margin-right: 10px;
    color: #084898;
  }
  
  .delete-button {
    color: #B83030;
  }
  
  .edit-button:hover,
  .delete-button:hover {
    background: none;
    border: none;
  }
  
  .edit-button:disabled {
    cursor: not-allowed;
    opacity: 0.5;
    color: #084898;
    background-color: transparent;
  }
  
  .deleted-user {
    background-color: #ffe6e6;
  }
  
  .button-group {
    display: flex;
    align-items: center;
  }