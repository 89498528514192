.dashboard {
  padding: 20px;
  font-family: Arial, sans-serif;
  max-width: 800px;
  margin: 0 auto; /* Center the form */
}

.dashboard h1 {
  text-align: center;
}

.section {
  margin-bottom: 40px;
}

.section h2 {
  text-align: center;
  margin-bottom: 20px;
}

.section h3 {
  margin-top: 20px;
}

ul {
  list-style-type: none;
  padding: 0;
}

ul li {
  margin: 5px 0;
}

.chart-container {
  width: 100%;
  max-width: 500px;
  max-height: 500px;
  margin: 0 auto;
}

.consent-table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
}

.consent-table th,
.consent-table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.consent-table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #f2f2f2;
  color: rgb(0, 0, 0);
}

.consent-table img {
  max-width: 50px;
  max-height: 30px;
}

.filters {
  max-width: 800px;
  margin: 20px auto;
  display: flex;
}

.filters label {
  font-size: 16px;
  color: #101E26;
  flex: 1;
  margin-left: 20px;
  align-content: center;
}

.filters input,
.filters select {
  padding: 15px;
  border: 2px solid #101E26;
  border-radius: 17px;
  font-size: 12px;
  margin-left: 20px;
  flex: 1;
}

.tabs {
  display: flex;
  margin-bottom: 20px;
}

.tabs button {
  padding: 10px 20px;
  margin: 0 10px;
  border: none;
  background-color: #c0c0c0;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.tabs button.active {
  background-color: #36A2EB;
  color: white;
}
