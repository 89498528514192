.add-surgery {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto; /* Center the form */
}

.add-surgery-form {
  margin-bottom: 20px;
}

.edit-button,
.delete-button {
  background: none;
  border: none;
  cursor: pointer;
  margin-right: 10px;
  color: #007bff;
}

.delete-button {
  color: #B83030;
}

.edit-button:hover,
.delete-button:hover {
  background: none;
  border: none;
}

.button-group {
  display: flex;
  align-items: center;
}

.deleted {
  background-color: #f8d7da;
}