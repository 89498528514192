.carousel-container {
    margin: 20px auto;
    text-align: center;
}

.slide {
    background: linear-gradient(135deg, #007BFF, #0056b3);
    color: white;
    padding: 60px 20px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.slick-dots li button:before {
    color: white !important;
}

.slick-prev, .slick-next {
    z-index: 10;
}

.carousel-container h1{
color: black;
}

.slide img {
    width: auto;
    align-items: center;
    height: 102px;
    border-radius: 10px;
    margin-bottom: 15px;
}