/* src/pages/ForgotPassword.css */
.forgot-password-container {
    align-items: center;
    height: 100vh;
    background: url('../assets/forgot-password.png') no-repeat center center/cover;
    display: flex;
    justify-content: center;
  }
  
  .forgot-password-box {
    background-color: rgba(255, 255, 255, 0.9);
    padding: 40px;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 400px;
  }
  
  .forgot-password-box h2 {
    margin-bottom: 20px;
    font-family: 'Futura', sans-serif;
    font-size: 24px;
    color: #084898;
  }
  .forgot-password-box p {
    color: #8A8D8E;
    font-size: 12px;
    margin: 0;
    margin-bottom: 40px;
  }
   

  
  